import Header from 'components/Header';
import JobDescription from 'pageComponents/Careerdetails/components/JobDescriptionWeb';
import JobDescriptionMobile from 'pageComponents/Careerdetails/components/JobDescriptionMobile';
import React from 'react';
import NavigationColor from 'utils/constants/NavigationColor';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { HeadProps, graphql } from 'gatsby';

export default ({ pageContext: { offer } }) => {
  return (
    <>
      <Header variant={NavigationColor.DARK} />
      <JobDescription offer={offer} />
      <JobDescriptionMobile offer={offer} />
    </>
  );
};

export function Head(props: HeadProps) {
  const { t } = useTranslation();
  return (
    <title>{t('home.atf.navigation_tabs.careers')} | Safebrok</title>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
