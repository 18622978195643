import React from 'react';
import * as Styles from '../styles';
import JobDescriptionContent from './JobDescriptionContent';

const JobDescriptionWeb = ({ offer }) => {
  const {
    roleTitle,
    location,
    description,
    requirements,
    responsibilities,
    skills,
    linkedinUrl,
  } = offer;

  return (
    <Styles.ContainerWeb>
      <JobDescriptionContent
        roleTitle={roleTitle}
        location={location}
        description={description}
        responsibilities={responsibilities}
        requirements={requirements}
        skills={skills}
        linkedinUrl={linkedinUrl}
      />
    </Styles.ContainerWeb>
  );
};

export default JobDescriptionWeb;
