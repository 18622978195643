import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import CheckboxInput from 'components/CheckboxInput';

import { nameValidation } from 'validations/JobFormValidations';
import { lastNameValidation } from 'validations/JobFormValidations';
import { emailValidation } from 'validations/JobFormValidations';
import { cvValidation } from 'validations/JobFormValidations';
import { privacyPolicyValidation } from 'validations/JobFormValidations';
import { phoneNumberValidation } from 'validations/JobFormValidations';

import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { MAXIMUM_CHARACTERS_TEXT_AREA } from 'utils/constants';
import getPhoneInputLocalization from 'utils/getPhoneInputLocalization';
import { breakpoints } from 'theme';
import NoLinkButton from 'components/NoLinkButton';

import CloseApplicationIcon from 'images/CloseApplicationIcon.png';
import UploadCvIcon from 'images/UploadCvIcon.png';
import RemoveCvIcon from 'images/RemoveCvIcon.png';
import ThanksForApplying from './ThanksForApplying';
import convertBase64 from 'utils/convertBase64';
import sendJobApplicationEmail from 'services/customer.io/sendJobApplicationEmail';
import LoadingSpinner from 'components/LoadingSpinner';

const StyledForm = styled.form`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: ${rem(8)};
  padding: 40px 40px 48px 40px;
  box-shadow: none;

  @media (max-width: ${breakpoints.tablet}) {
    padding-top: 0px;
  }

  input,
  select,
  textarea {
    border: 1px solid #e9ecef;
    border-radius: ${rem(8)};
    padding: 12px 0 12px 16px;

    color: ${(props) => props.theme.brandPalette.dark};
    font-size: ${rem(16)};
    line-height: ${rem(24)};

    transition: all 150ms ease-in-out;

    &::placeholder {
      color: ${(props) => props.theme.neutralPalette.neutral6};
    }
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.brandPalette.primary};
  }

  .phoneInputContainer {
    border: 1px solid #e9ecef;
    width: 100% !important;

    &:focus {
      border: 1px solid ${(props) => props.theme.brandPalette.primary};
      box-shadow: none;
    }
  }

  .buttonContainer {
    border-right: 1px solid #e9ecef;

    .open::before {
      border: 1px solid ${(props) => props.theme.brandPalette.primary};
      box-shadow: 0 0 0 0.2rem rgba(2, 196, 178, 0.4);
    }

    .arrow {
      border-top: 4px solid #02c4b2;

      &.up {
        border-bottom: 4px solid #02c4b2;
      }
    }
  }
`;

const FormTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.brandPalette.dark};
  font-size: ${rem(24)};
  line-height: ${rem(34)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};

  margin-bottom: ${rem(48)};

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: ${rem(32)};

    img {
      display: none;
    }
  }

  img {
    width: ${rem(13)};
    height: ${rem(13)};
    cursor: pointer;
    color: ${(props) => props.theme.brandPalette.dark};
  }
`;

const FormGroup = styled.div<{ showErrorLabel: boolean }>`
  position: relative;

  input {
    width: 100%;
    border: 1px solid
      ${(props) =>
        props.showErrorLabel
          ? props.theme.semanticPalette.error.base
          : '#e9ecef'};
  }

  label {
    display: ${(props) => (props.showErrorLabel ? 'block' : 'none')};
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    font-weight: ${(props) => props.theme.fontWeights.regular};

    position: absolute;
    z-index: 1;
    padding: ${rem(4)};
    background-color: white;
    top: -12px;
    left: ${rem(16)};
    color: red;
  }
`;

const AboutYourselfInput = styled.textarea`
  resize: none;
`;

const UploadInput = styled.label`
  border: 1px solid #e9ecef;
  border-radius: ${rem(8)};
  padding: 12px 16px;
  color: ${(props) => props.theme.brandPalette.primary};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  user-select: none;
  cursor: pointer;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-top: ${rem(54)};
  margin-bottom: ${rem(38)};

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    gap: ${rem(32)};
    margin-top: ${rem(62)};
    margin-bottom: ${rem(32)};
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(32)};

  img {
    width: ${rem(20)};
    height: ${rem(20)};
  }

  @media (max-width: ${breakpoints.tablet}) {
    img {
      width: 30px;
      height: 15px;
    }
  }
`;

const PrivacyCheckboxLabel = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  margin-top: 35px;
  margin-left: 3px;

  &:hover {
    cursor: pointer;
  }

  a {
    font-weight: ${(props) => props.theme.fontWeights.bold};

    transition: all 150ms ease-in-out;

    &:hover {
      color: ${(props) => props.theme.brandPalette.secondary};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: 0px;
  }
`;

const SubmitButton = styled.div`
  margin-top: ${rem(-20)};
  text-align: center;

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: ${rem(10)};
  }

  button {
    width: 100%;
  }

  button:disabled {
    opacity: 0.1;
  }
`;

const ErrorMessageContainer = styled.div`
  margin: -25px 0 -55px 5px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.tablet}) {
    margin: -25px 0 -25px 10px;
  }
`;
const ErrorMessagePrivacyPolicy = styled.div`
  margin: -25px 0 -55px 7px;

  @media (max-width: ${breakpoints.tablet}) {
    margin: -25px 0 -25px 10px;
  }
`;

const ErrorMessageOnForm = styled.p`
  font-weight: 400;
  font-size: ${rem(10)};
  line-height: ${rem(18)};
  color: #d35549;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(12)};
  }
`;

const FileName = styled.p`
  color: ${(props) => props.theme.brandPalette.dark} !important;
  font-weight: ${(props) => props.theme.fontWeights.regular} !important;
  font-size: ${rem(16)} !important;
  line-height: ${rem(24)} !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
`;

const InputPlaceholder = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.brandPalette.primary} !important;
  font-weight: ${(props) => props.theme.fontWeights.semibold} !important;
  font-size: ${rem(16)} !important;
  line-height: ${rem(24)} !important;
  margin: 0 !important;
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.semanticPalette.error.base};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(14)};
  margin-top: 32px;
`;

interface FormProps {
  positionTitle: string;
  positionLocation: string;
  toggleModalForm?: any;
}

const Form = ({
  positionTitle,
  positionLocation,
  toggleModalForm,
}: FormProps) => {
  const [linkedinUrl, setLinkedinUrl] = useState<string>('');
  const [aboutYourselfInput, setAboutYourselfInput] = useState<string>('');
  const [formSubmitedWithSuccess, setFormSubmitedWithSuccess] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);

  const fileInputRef = useRef(null);

  const [dirty, setDirty] = useState({
    name: false,
    lastName: false,
    email: false,
    cv: false,
    privacyPolicy: false,
    phoneNumber: false,
  });
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [cv, setCv] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [errors, setErrors] = useState({
    name: '',
    lastName: '',
    email: '',
    cv: '',
    privacyPolicy: '',
    phoneNumber: '',
  });

  const { i18n, t } = useTranslation();

  const phoneInputLanguage = getPhoneInputLocalization(i18n.language);

  const handleName = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(e.target.value);
    setDirty((prev) => ({ ...prev, name: true }));
  };

  const handleLastName = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLastName(e.target.value);
    setDirty((prev) => ({ ...prev, lastName: true }));
  };

  const handleEmail = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
    setDirty((prev) => ({ ...prev, email: true }));
  };

  const handlePrivacyPolicy = (privacyPolicy: boolean) => {
    setPrivacyPolicy(privacyPolicy);
    setDirty((prev) => ({ ...prev, privacyPolicy: true }));
  };

  const handlePhoneNumber = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    setDirty((prev) => ({ ...prev, phoneNumber: true }));
  };

  const handleLinkedin = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLinkedinUrl(e.target.value);
  };

  const handleAboutYourselfInput = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAboutYourselfInput(e.target.value);
  };

  useEffect(() => {
    const nameValidationOnForm = dirty.name ? nameValidation(name) : '';
    const lastNameValidationOnForm = dirty.lastName
      ? lastNameValidation(lastName)
      : '';
    const emailValidationOnForm = dirty.email ? emailValidation(email) : '';
    const cvValidationOnForm = dirty.cv ? cvValidation(cv) : '';
    const privacyPolicyValidationOnForm = dirty.privacyPolicy
      ? privacyPolicyValidation(privacyPolicy)
      : '';
    const phoneNumberValidationOnForm = dirty.phoneNumber
      ? phoneNumberValidation(phoneNumber)
      : '';

    setErrors((prev) => ({
      ...prev,
      name: nameValidationOnForm,
      lastName: lastNameValidationOnForm,
      email: emailValidationOnForm,
      cv: cvValidationOnForm,
      privacyPolicy: privacyPolicyValidationOnForm,
      phoneNumber: phoneNumberValidationOnForm,
    }));
  }, [name, lastName, email, cv, privacyPolicy, phoneNumber]);

  const toggleFormSubmitedWithSuccess = () => {
    setFormSubmitedWithSuccess(!formSubmitedWithSuccess);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const submissionDestination = 'rrhh@safebrok.com';

    try {
      setIsLoading(true);

      const base64File = await convertBase64(fileInputRef.current?.files[0]);

      const res = await sendJobApplicationEmail({
        formData: {
          firstName: name,
          lastName: lastName,
          email: email,
          linkedinUrl: linkedinUrl,
          aboutYourself: aboutYourselfInput,
          phoneNumber: phoneNumber,
        },
        jobPosition: positionTitle,
        positionLocation: positionLocation,
        submissionDestination: submissionDestination,
        base64File,
      });

      if (!res.ok) {
        throw new Error('Something went wrong, please try again.');
      }

      toggleFormSubmitedWithSuccess();
    } catch (error) {
      setFormErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = () => {
    if (!fileInputRef.current) return;

    const file = fileInputRef.current.files[0];
    setUploadedFileName(file.name);

    setCv(file.name);
    setDirty((prev) => ({ ...prev, cv: true }));
  };

  const handleClearFileInput = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    if (!fileInputRef.current) return;

    event.preventDefault();
    fileInputRef.current.files = null;
    setUploadedFileName(null);
    setCv('');
  };

  const isFormValid = Object.values(errors).every(
    (error) => error.length === 0,
  );

  const isAllFieldsDirty = Object.values(dirty).every((dirtyFlag) => dirtyFlag);

  return (
    <StyledForm>
      {!formSubmitedWithSuccess ? (
        <>
          <FormTitle>
            Apply for {positionTitle}
            <img
              onClick={toggleModalForm}
              src={CloseApplicationIcon}
              alt=""
              arria-hidden="true"
            />
          </FormTitle>
          <FlexColumn>
            <FormGroup showErrorLabel={errors.name.length > 0}>
              <label htmlFor="">First Name*</label>
              <input
                type="text"
                name=""
                id=""
                placeholder={errors.name.length > 0 ? '' : 'First Name*'}
                onChange={handleName}
                required
              />
            </FormGroup>
            {errors.name.length > 0 && (
              <ErrorMessageContainer>
                <ErrorMessageOnForm>{errors.name}</ErrorMessageOnForm>
              </ErrorMessageContainer>
            )}
            <FormGroup showErrorLabel={errors.lastName.length > 0}>
              <label htmlFor="">Last Name*</label>
              <input
                type="text"
                name=""
                id=""
                placeholder={errors.lastName.length > 0 ? '' : 'Last Name*'}
                onChange={handleLastName}
                required
              />
            </FormGroup>
            {errors.lastName.length > 0 && (
              <ErrorMessageContainer>
                <ErrorMessageOnForm>{errors.lastName}</ErrorMessageOnForm>
              </ErrorMessageContainer>
            )}
            <FormGroup showErrorLabel={errors.email.length > 0}>
              <label htmlFor="">Email*</label>
              <input
                type="email"
                name=""
                id=""
                placeholder={
                  errors.lastName.length > 0
                    ? ''
                    : t('contacts.contact_section.email_placeholder')
                }
                onChange={handleEmail}
                required
              />
            </FormGroup>
            {errors.email.length > 0 && (
              <ErrorMessageContainer>
                <ErrorMessageOnForm>{errors.email}</ErrorMessageOnForm>
              </ErrorMessageContainer>
            )}
            <FormGroup showErrorLabel={errors.phoneNumber.length > 0}>
              <label htmlFor="">Phone number*</label>
              <PhoneInput
                placeholder={t('contacts.contact_section.number_placeholder')}
                country={i18n.language}
                localization={phoneInputLanguage}
                preferredCountries={['es', 'pt', 'it']}
                countryCodeEditable={false}
                onChange={handlePhoneNumber}
                inputProps={{
                  name: 'phone',
                  required: true,
                }}
                containerClass="container"
                inputClass="phoneInputContainer"
                buttonClass="buttonContainer"
              />
            </FormGroup>
            {errors.phoneNumber.length > 0 && (
              <ErrorMessageContainer>
                <ErrorMessageOnForm>{errors.phoneNumber}</ErrorMessageOnForm>
              </ErrorMessageContainer>
            )}
            <input
              type="text"
              name=""
              id=""
              placeholder="LinkedIn"
              onChange={handleLinkedin}
            />
            <AboutYourselfInput
              name=""
              id=""
              maxLength={MAXIMUM_CHARACTERS_TEXT_AREA}
              placeholder="Tell us about yourself"
              onChange={handleAboutYourselfInput}
            />
            <input
              type="file"
              required
              ref={fileInputRef}
              hidden
              name="cv"
              id="cv"
              onChange={handleFileUpload}
            />
            <UploadInput htmlFor="cv">
              {uploadedFileName ? (
                <FileName>
                  {uploadedFileName}
                  <img
                    src={RemoveCvIcon}
                    alt="Remove File"
                    onClick={handleClearFileInput}
                  />
                </FileName>
              ) : (
                <InputPlaceholder>
                  Upload CV* <img src={UploadCvIcon} alt="Upload File" />
                </InputPlaceholder>
              )}
            </UploadInput>
            {errors.cv.length > 0 && (
              <ErrorMessageContainer>
                <ErrorMessageOnForm>{errors.cv}</ErrorMessageOnForm>
              </ErrorMessageContainer>
            )}
          </FlexColumn>
          <Grid>
            <CheckboxInput
              id="policy"
              showErrorLabel={errors.privacyPolicy.length > 0}
              onChange={handlePrivacyPolicy}
            >
              <PrivacyCheckboxLabel>
                <Trans i18nKey="contacts.contact_section.privacy_policy_check">
                  Text
                  <a href="https://www.google.com/" target="_blank">
                    Link
                  </a>
                </Trans>
              </PrivacyCheckboxLabel>
              {errors.privacyPolicy.length > 0 && (
                <ErrorMessagePrivacyPolicy>
                  <ErrorMessageOnForm>
                    {errors.privacyPolicy}
                  </ErrorMessageOnForm>
                </ErrorMessagePrivacyPolicy>
              )}
            </CheckboxInput>
          </Grid>
          <SubmitButton>
            <NoLinkButton
              invertedColors={false}
              onClick={handleSubmit}
              disabled={!(isAllFieldsDirty && isFormValid)}
            >
              Submit application
            </NoLinkButton>
            {isLoading && <LoadingSpinner />}
            {formErrorMessage && (
              <ErrorMessage>{formErrorMessage}</ErrorMessage>
            )}
          </SubmitButton>
        </>
      ) : (
        <ThanksForApplying goBack={() => toggleModalForm()} />
      )}
    </StyledForm>
  );
};

export default Form;
