import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const StyledButton = styled.button<{ invertedColors?: boolean }>`
  background-color: ${({ invertedColors, theme }) =>
    invertedColors ? theme.neutralPalette.white : theme.brandPalette.primary};
  border: ${({ invertedColors, theme }) =>
    invertedColors
      ? `1px solid ${theme.neutralPalette.white}`
      : `1px solid ${theme.brandPalette.primary}`};
  color: ${({ invertedColors, theme }) =>
    invertedColors ? theme.brandPalette.primary : theme.neutralPalette.white};

  padding: 12px 24px;
  border-radius: 8px;

  font-size: ${rem(16)};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid
      ${({ invertedColors, theme }) =>
        invertedColors
          ? theme.neutralPalette.white
          : theme.brandPalette.primary};
    color: ${({ invertedColors, theme }) =>
      invertedColors ? theme.neutralPalette.white : theme.brandPalette.primary};
    background-color: transparent;
  }
`;

interface ButtonProps {
  children: React.ReactNode;
  invertedColors?: boolean;
  onClick?: any;
  onChange?: any;
  disabled?: any;
}

const Button = ({ children, invertedColors, onClick, onChange, disabled }: ButtonProps) => {
  return (
    <>
      <StyledButton onClick={onClick} invertedColors={invertedColors} onChange={onChange} disabled={disabled}>
        {children}
      </StyledButton>
    </>
  );
};

export default Button;
