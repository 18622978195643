import React, { useState } from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import NoLinkButton from 'components/NoLinkButton';
import Button from 'components/Button';

import * as Styles from '../styles';

import FuncionesIcon from 'images/DetailsFunciones.png';
import RequisitosIcon from 'images/DetailsRequisitos.png';
import CompetenciasIcon from 'images/DetailsCompetencias.png';
import IconsBackground from 'images/DetailsIconbg.png';
import DetailsArrow from 'images/DetailsArrow.png';
import FormJobSubmit from './FormJobSubmit';
import styled from 'styled-components';

interface FormProps {
  roleTitle: string;
  location: string;
  description: string;
  responsibilities: string;
  requirements: string;
  skills: string;
  linkedinUrl: string;
}

const LinkedinBtn = styled(Button)`
  &:hover {
    background-color: ${({ theme }) => theme.brandPalette.primary};
  }
`;

const JobDescriptionContent = ({
  roleTitle,
  location,
  description,
  responsibilities,
  requirements,
  skills,
  linkedinUrl,
}: FormProps) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <Styles.TitleWrapper>
        <Styles.LinkWrapper>
          <Link to="/careers" className="apply-link">
            <img
              src={DetailsArrow}
              className="imageArrow"
              alt=""
              aria-hidden="true"
            />
            Talento
          </Link>
        </Styles.LinkWrapper>
        <Styles.TopContainer>
          <Styles.LinkWrapperMob>
            <Link to="/careers" className="apply-link">
              <img
                src={DetailsArrow}
                className="imageArrow"
                alt=""
                aria-hidden="true"
              />
              Talento
            </Link>
          </Styles.LinkWrapperMob>
          <h6> {t('opportunity_page.header.oferta')} </h6>
          <h3>{roleTitle}</h3>
          <h6>Located in {location}</h6>
        </Styles.TopContainer>
      </Styles.TitleWrapper>
      <Styles.DescriptionContainer>
        <Styles.Description>
          <h6>{description}</h6>
        </Styles.Description>
      </Styles.DescriptionContainer>
      <Styles.SectionTwoWrapper>
        <Styles.OfferLists>
          <Styles.HeadingImage>
            <img
              src={FuncionesIcon}
              className="image1"
              alt=""
              aria-hidden="true"
            />
            <img
              src={IconsBackground}
              className="image2"
              alt=""
              aria-hidden="true"
            />
            <h5>{t('opportunity_page.job_description.responsibilities')}</h5>
          </Styles.HeadingImage>
          <ul>
            <li>{responsibilities}</li>
          </ul>
        </Styles.OfferLists>
        <Styles.OfferLists>
          <Styles.HeadingImage>
            <img
              src={RequisitosIcon}
              className="image1"
              alt=""
              aria-hidden="true"
            />
            <img
              src={IconsBackground}
              className="image2"
              alt=""
              aria-hidden="true"
            />
            <h5>{t('opportunity_page.job_description.requirements')}</h5>
          </Styles.HeadingImage>
          <ul>
            <li>{requirements}</li>
          </ul>
        </Styles.OfferLists>
        <Styles.OfferLists>
          <Styles.HeadingImage>
            <img
              src={CompetenciasIcon}
              className="image1"
              alt=""
              aria-hidden="true"
            />
            <img
              src={IconsBackground}
              className="image2"
              alt=""
              aria-hidden="true"
            />
            <h5>{t('opportunity_page.job_description.skills')}</h5>
          </Styles.HeadingImage>
          <ul>
            <li>{skills}</li>
          </ul>
        </Styles.OfferLists>
        <Styles.ApplyWrapper>
          <div>
            <h5>{t('opportunity_page.job_description.how_to_apply')}</h5>
            <p>
              {t('opportunity_page.job_description.application_instructions')}
            </p>
          </div>
          <Styles.ApplyButtonsWeb>
            <NoLinkButton invertedColors={false} onClick={toggleModal}>
              {t('opportunity_page.job_description.apply')}
            </NoLinkButton>
            {linkedinUrl !== '' && (
              <a href={linkedinUrl} target="_blank">
                <LinkedinBtn invertedColors>
                  {t('opportunity_page.job_description.linkedin')}
                </LinkedinBtn>
              </a>
            )}
          </Styles.ApplyButtonsWeb>
          {modal && (
            <Styles.Modal>
              <Styles.JobApplicationForm>
                <FormJobSubmit
                  positionTitle={roleTitle}
                  positionLocation={location}
                  toggleModalForm={toggleModal}
                />
              </Styles.JobApplicationForm>
            </Styles.Modal>
          )}
        </Styles.ApplyWrapper>
      </Styles.SectionTwoWrapper>
    </>
  );
};

export default JobDescriptionContent;
