export const nameValidation = (name: string | any[]) => {
  if (name.length == 0) {
    return 'Name required';
  }
  return '';
};
export const lastNameValidation = (lastName: string | any[]) => {
  if (lastName.length == 0) {
    return 'Last name required';
  }
  return '';
};

export const emailValidation = (email: string | any[]) => {
  if (email.length == 0) {
    return 'Email required';
  }
  return '';
};

export const cvValidation = (cv: string | any[]) => {
  if (cv.length == 0) {
    return 'CV required';
  }
  return '';
};

export const privacyPolicyValidation = (privacyPolicy: boolean | any[]) => {
  if (privacyPolicy !== true) {
    return ' ';
  }
  return '';
};

export const phoneNumberValidation = (phoneNumber: string | any[]) => {
  if (phoneNumber.length < 5) {
    return 'Please enter a valid phone number.';
  }
  return '';
};



