import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from 'theme';

export const ContainerWeb = styled.div`
  align-items: center;

  .apply-link {
    cursor: pointer;
    font-weight: ${(props) => props.theme.fontWeights.medium};
    font-size: ${rem(20)};
    color: ${(props) => props.theme.brandPalette.primary};
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const ContainerMobile = styled.div`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
    align-items: center;
    margin-bottom: ${rem(25)};

    .apply-link {
      cursor: pointer;
      font-weight: ${(props) => props.theme.fontWeights.medium};
      font-size: ${rem(20)};
      color: ${(props) => props.theme.brandPalette.primary};
    }
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
`;

export const LinkWrapper = styled.div`
  margin-right: ${rem(30)};
  margin-left: ${rem(52)};

  img {
    padding-right: ${rem(16)};
  }
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;
export const LinkWrapperMob = styled.div`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
    margin-bottom: ${rem(26)};
    margin-top: ${rem(50)};

    img {
      cursor: pointer;
      width: ${rem(13)};
      height: ${rem(13)};
      margin-right: ${rem(10)};
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 80px 363px 56px 180px;

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    color: ${(props) => props.theme.neutralPalette.neutral6};
  }

  h3 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(32)};
    line-height: ${rem(46)};
    color: ${(props) => props.theme.brandPalette.dark};
    margin: 16px 0;
  }

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: center;
    padding: 80px 24px 10px 24px;

    h6 {
      font-weight: ${(props) => props.theme.fontWeights.light};
      margin-bottom: ${rem(-7)};
    }
    h3 {
      margin-bottom: -2px;
    }
  }
`;

export const Description = styled.div`
  padding: 56px 365px;

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    color: ${(props) => props.theme.brandPalette.dark};
    opacity: 0.8;
  }

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: center;
    padding: 40px 24px;
  }
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: ${(props) => props.theme.brandPalette.light};

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: ${rem(40)};
  }
`;
export const SectionTwoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 56px 363px;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: start;
    padding: 40px 30px;
  }
`;

export const OfferLists = styled.div`
  margin-bottom: ${rem(63)};
  position: relative;
  top: 0;
  left: 0;

  h5 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(24)};
    line-height: ${rem(34)};
    margin-bottom: ${rem(23)};
  }

  li {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    color: ${(props) => props.theme.brandPalette.dark};
    opacity: 0.8;
    margin-left: ${rem(68)};
    list-style: none;
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin: 50px 0 40px 0;

    h5 {
      font-size: ${rem(20)};
      line-height: ${rem(25)};
    }
    ul {
      text-align: left;
      font-size: ${rem(18)};
      line-height: ${rem(32)};
      margin-top: ${rem(52)};
    }
    li {
      margin-left: ${rem(0)};
    }
  }
`;
export const ApplyWrapper = styled.div`
  h5 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(24)};
    line-height: ${rem(34)};
    margin-bottom: ${rem(24)};
  }
  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    margin-bottom: ${rem(30)};
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: ${rem(50)};

    h5 {
      font-size: ${rem(20)};
      line-height: ${rem(25)};
    }
    p {
      font-size: ${rem(18)};
    }
  }
`;

export const HeadingImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-bottom: ${rem(26)};
    margin-right: ${rem(25)};
    color: ${(props) => props.theme.brandPalette.primary};
  }
  .image1 {
    position: relative;
    top: 0;
    left: 0;
  }
  .image2 {
    position: absolute;
    top: -10px;
    left: -${rem(13)};
    border-radius: ${rem(8)};
  }
`;

export const ApplyButtonsWeb = styled.div`
  button {
    border: 1px solid #e9ecef;
    margin-right: ${rem(16)};
  }
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;
export const ApplyButtonsMob = styled.div`
  @media (max-width: ${breakpoints.tablet}) {
    display: block;
    margin-top: ${rem(-20)};
    border-radius: ${rem(8)};
    padding: 0 0 0 20px;

    button {
      width: 90%;
      margin-bottom: ${rem(16)};
      border: 1px solid #e9ecef;
    }
  }
`;

export const Modal = styled.div`
  position: fixed;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(41, 48, 64, 0.7);
  z-index: 999;

  @media (max-width: ${breakpoints.tablet}) {
    background: none;
    top: ${rem(80)};
  }
`;

export const JobApplicationForm = styled.div`
  width: ${rem(550)};
  max-height: ${rem(640)};
  overflow-y: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: ${rem(16)};

  @media (max-width: ${breakpoints.tablet}) {
    height: ${rem(1000)};
    left: 50%;
    top: 30%;
  }
`;

export const HeadingApplicationFormMob = styled.div`
  padding-top: ${rem(100)};
  text-align: left;
  align-items: center;

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(18)};
    line-height: ${rem(32)};
    font-weight: ${(props) => props.theme.fontWeights.light};
    margin-bottom: ${rem(10)};
    color: ${(props) => props.theme.neutralPalette.neutral6};
    padding: 0 40px 0 40px;
  }
  img {
    padding-right: ${rem(13)};
  }
`;

export const ReturnButtonApplicationForm = styled.button`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(18)};
  line-height: 22px;
  color: #02c4b2;
  background: none;
  padding: 40px 40px 26px 40px;
  cursor: pointer;
`;
