interface RequestParams {
  formData: JobApplicationFormDTO;
  base64File: any;
  submissionDestination: string;
  jobPosition: string;
  positionLocation: string;
}

const sendJobApplicationEmail = async ({
  formData,
  base64File,
  submissionDestination,
  jobPosition,
  positionLocation,
}: RequestParams) => {
  const body = {
    to: submissionDestination,
    transactional_message_id: 'job_application',
    identifiers: {
      email: submissionDestination,
    },
    message_data: {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      position: jobPosition,
      phone_number: `+${formData.phoneNumber}`,
      message: formData.aboutYourself || '',
      linked_in: formData.linkedinUrl || '',
      position_location: positionLocation,
    },
    attachments: {
      CV: base64File,
    },
  };

  const res = await fetch('https://api-eu.customer.io/v1/send/email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.GATSBY_CUSTOMER_IO_API_KEY}`,
    },
    body: JSON.stringify(body),
  });

  return res;
};

export default sendJobApplicationEmail;
