import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import ApplicationSubmitedIcon from 'images/ApplicationSubmitedIcon.png';
import { breakpoints } from 'theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;

  img {
    margin-top: ${rem(15)};
  }

  h4 {
    display: none;
  }

  @media (max-width: ${breakpoints.tablet}) {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    padding: ${rem(12)};

    h4 {
      display: block;
      margin-bottom: ${rem(15)};
    }
  }

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    text-align: center;
    color: #293040;
    margin: 32px 46px 24px 46px;
  }

  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    text-align: center;
    color: #4f575e;
    margin: 0 56px 0 56px;
  }

  button {
    cursor: pointer;
    background: none;
  }

  h6 {
    margin: 32px 65px 0 65px;
    padding: 0;
  }

  p {
    margin: 32px 65px 0 65px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

interface ThanksForApplyingProps {
  goBack(): void;
}

const ThanksForApplying = ({ goBack }: ThanksForApplyingProps) => {
  return (
    <Wrapper>
      <Header>
        <h4>Application submitted!</h4>
      </Header>
      <Content>
        <button onClick={goBack}>
          <img src={ApplicationSubmitedIcon} alt="" arria-hidden="true" />
        </button>
        <h6>Thank you for submiting your application</h6>
        <p>
          We`ll look over your application and get back to you as soon as
          possible
        </p>
      </Content>
    </Wrapper>
  );
};

export default ThanksForApplying;
