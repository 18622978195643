import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import NoLinkButton from 'components/NoLinkButton';
import Button from 'components/Button';
import * as Styles from '../styles';
import DetailsArrow from 'images/DetailsArrow.png';
import FormJobSubmit from './FormJobSubmit';

import JobDescriptionContent from './JobDescriptionContent';
import styled from 'styled-components';

const LinkedinBtn = styled(Button)`
  &:hover {
    background-color: ${({ theme }) => theme.brandPalette.primary};
  }
`;

const JobDescriptionMobile = ({ offer }) => {
  const { t } = useTranslation();
  const [openApplicationForMobile, setOpenApplicationForMobile] =
    useState(true);

  const {
    roleTitle,
    location,
    description,
    requirements,
    responsibilities,
    skills,
    linkedinUrl,
  } = offer;

  const handleOpenApplicationForMobile = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setOpenApplicationForMobile(!openApplicationForMobile);
  };

  return (
    <Styles.ContainerMobile>
      {openApplicationForMobile ? (
        <>
          <JobDescriptionContent
            roleTitle={roleTitle}
            location={location}
            description={description}
            responsibilities={responsibilities}
            requirements={requirements}
            skills={skills}
            linkedinUrl={linkedinUrl}
          />
          <Styles.ApplyButtonsMob>
            <NoLinkButton
              invertedColors={false}
              onClick={handleOpenApplicationForMobile}
            >
              {t('opportunity_page.job_description.apply')}
            </NoLinkButton>
            {linkedinUrl !== '' && (
              <a href={linkedinUrl} target="_blank">
                <LinkedinBtn invertedColors>
                  {t('opportunity_page.job_description.linkedin')}
                </LinkedinBtn>
              </a>
            )}
          </Styles.ApplyButtonsMob>
        </>
      ) : (
        <Styles.HeadingApplicationFormMob>
          <Styles.ReturnButtonApplicationForm
            onClick={handleOpenApplicationForMobile}
          >
            <img
              src={DetailsArrow}
              className="imageArrow"
              alt=""
              aria-hidden="true"
            />
            Oferta
          </Styles.ReturnButtonApplicationForm>
          <h6>{roleTitle}</h6>
          <FormJobSubmit
            positionLocation={location}
            positionTitle={roleTitle}
            toggleModalForm={handleOpenApplicationForMobile}
          />
        </Styles.HeadingApplicationFormMob>
      )}
    </Styles.ContainerMobile>
  );
};

export default JobDescriptionMobile;
